/**
 *
 * Mutations
 */

import { useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { SeniorLivingReviewPayload } from '~/types/reviews';
import { getUTMParameters } from '@utils/utmParameters';
import camelCase from 'lodash/camelCase';
/**
 * Create Senior living review
 */

export const useCreateSeniorLivingReviewMutation = () => {
  const toast = useToast();

  return useMutation(async (data: SeniorLivingReviewPayload) => {
    const utmParameters = getUTMParameters();
    const formattedUtmParameters = Object.keys(utmParameters).reduce(
      (acc, key) => {
        acc[camelCase(key)] = utmParameters[key];
        return acc;
      },
      {}
    );
    const response = await fetch('/api/reviews/create-review', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...data, ...formattedUtmParameters }),
    });

    const responseJson = await response.json();

    if (response.status >= 400 && responseJson.message) {
      responseJson.message.map((description: string) => {
        toast({
          description,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      });

      throw new Error(responseJson);
    } else if (response.status >= 400) {
      toast({
        description: 'An unexpected error has occurred, please try again later',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      throw new Error(responseJson);
    }

    return responseJson;
  });
};
