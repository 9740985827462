import { SearchIcon } from '@chakra-ui/icons';
import {
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Show,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import {
  AutoComplete,
  AutoCompleteGroup,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import { useProvidersSearchQuery } from '@hooks/provider';
import { convertProviderServiceToLocationServiceOption } from '@utils/providers';
import { useContext, useState } from 'react';

import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { DEFAULT_HITS_PER_PAGE, DEFAULT_PAGE, REVIEW_TYPES } from '~/constants';
import ProviderContext, { Provider } from '~/contexts/Provider';
import ContactUs from './ContactUs';
import ReviewGuidelines from './ReviewGuidelines';
import ReviewStarsInput from './ReviewStarsInput';
import {
  DEFAULT_CARE_TYPES,
  RELATIONSHIPS_TO_PROVIDER,
  REVIEW_FIELD_LABELS,
  REVIEW_FIELD_PLACEHOLDERS,
  REVIEW_RATING_LABELS,
} from '@components/Account/Reviews/contants';
import { usStates, getStateAbbreviation } from '~/utils/UsStates';
import useDebounce from '@hooks/useDebounce';

interface ReviewFormProps {
  reviewBody: string;
  authorName: string;
  authorEmail: string;
  title: string;
  careType: string;
  city: string;
  state: string;
  ratingOverall: string;
  ratingFood: string;
  ratingActivities: string;
  ratingStaff: string;
  ratingFacilities: string;
  ratingValue: string;
  provider: string;
  facilityName: string;
  locationId: string;
  legacyResourceId: string;
}

type Ratings =
  | 'ratingOverall'
  | 'ratingStaff'
  | 'ratingActivities'
  | 'ratingFood'
  | 'ratingFacilities'
  | 'ratingValue';

interface Props {
  register: UseFormRegister<ReviewFormProps>;
  setValue: UseFormSetValue<ReviewFormProps>;
  setError: UseFormSetError<ReviewFormProps>;
  trigger: UseFormTrigger<ReviewFormProps>;
  errors: Partial<FieldErrorsImpl<ReviewFormProps>>;
  control?: Control<ReviewFormProps>;
  formType: REVIEW_TYPES;
  reviewGuidelinesURL?: string;
  contactUsURL?: string;
}

const ReviewForm: React.FC<Props> = ({
  control,
  register,
  errors,
  setValue,
  setError,
  trigger,
  formType = REVIEW_TYPES.SENIOR_LIVING,
  reviewGuidelinesURL,
  contactUsURL,
}) => {
  const [keywords, setKeywords] = useState('');
  const debouncedKeywords = useDebounce(keywords, 500);
  const { setProvider, provider } = useContext(ProviderContext) || {};

  const { data } = useProvidersSearchQuery({
    hitsPerPage: DEFAULT_HITS_PER_PAGE,
    page: DEFAULT_PAGE,
    keywords: debouncedKeywords,
    formType,
  });
  const providers = data?.results as Provider[] | undefined;
  const services = convertProviderServiceToLocationServiceOption(
    provider?.services || []
  );
  const displaySpecialFields = formType !== REVIEW_TYPES.SENIOR_CARE;
  const selectedProviderIsNew = !provider;
  const showOptionOfNewProvider = Boolean(keywords);

  const handleChangeStars = (input: Ratings, value: string) => {
    setValue(input, value);
    setError(input, { type: 'custom', message: '' });
  };

  const handleProviderChange = (value: string) => {
    setValue('legacyResourceId', '');
    const provider = providers?.find((provider) => provider.id === value);
    if (value === 'new-provider' || !provider) {
      setValue('locationId', '');
      setValue('provider', '');
    } else {
      setValue('locationId', provider.id || '');
      setValue('facilityName', provider.name || '');
      setKeywords(provider.name || '');

      // Fill-up city & state, and remove any existing validation error
      setValue('provider', provider.name || '');
      setValue('city', provider.address?.city || '');
      setValue(
        'state',
        getStateAbbreviation(provider.address?.state || '') || ''
      );
      trigger(['city', 'state']);
    }
    if (setProvider) setProvider(provider || null);
  };

  const handleProviderInputChange = (value: string) => {
    setValue('locationId', '');
    setValue('facilityName', value || '');
    setValue('careType', '');
    setValue('legacyResourceId', '');
    setValue('provider', '');
    if (setProvider) setProvider(null);
    setKeywords(value || '');
  };

  return (
    <VStack spacing="8" pr={{ base: 4, lg: 0 }}>
      {formType === REVIEW_TYPES.GENERIC && control && (
        <>
          <FormControl isInvalid={Boolean(errors.facilityName)} mt={8}>
            <FormLabel>Provider</FormLabel>
            <AutoComplete
              openOnFocus={showOptionOfNewProvider}
              onChange={handleProviderChange}
              disableFilter
            >
              <InputGroup>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <AutoCompleteInput
                      {...field}
                      variant="outline"
                      placeholder="Search"
                      onChange={(event) =>
                        handleProviderInputChange(event.target.value)
                      }
                      autoComplete="off"
                      isInvalid={Boolean(errors.facilityName)}
                    />
                  )}
                  name="facilityName"
                />

                <InputRightElement>
                  <SearchIcon />
                </InputRightElement>
              </InputGroup>
              <AutoCompleteList mt={1} maxW="632px">
                {providers?.map((provider, oid) => (
                  <AutoCompleteGroup key={`option-${oid}`}>
                    <AutoCompleteItem
                      value={provider.id}
                      textTransform="capitalize"
                      align="center"
                    >
                      <VStack align="start" w="full">
                        <Text fontSize={14} fontWeight="bold">
                          {provider.name}
                        </Text>
                        <Text fontSize={14}>
                          {provider.address?.formattedAddress}
                        </Text>
                        <Divider />
                      </VStack>
                    </AutoCompleteItem>
                  </AutoCompleteGroup>
                ))}
                {showOptionOfNewProvider && (
                  <AutoCompleteGroup key="option-new-provider">
                    <AutoCompleteItem
                      value="new-provider"
                      textTransform="capitalize"
                      align="center"
                    >
                      <VStack align="start" w="full">
                        <Text fontSize={14} fontWeight="bold">
                          {keywords} (New Provider)
                        </Text>
                        <Divider />
                      </VStack>
                    </AutoCompleteItem>
                  </AutoCompleteGroup>
                )}
              </AutoCompleteList>
            </AutoComplete>

            <FormErrorMessage>
              {String(errors?.facilityName?.message)}
            </FormErrorMessage>
          </FormControl>
          <Stack
            spacing="8"
            direction={{ base: 'column', lg: 'row' }}
            width="full"
          >
            <FormControl isInvalid={Boolean(errors.city)}>
              <FormLabel fontSize="sm">City</FormLabel>
              <Input
                {...register('city', { required: true })}
                isDisabled={!selectedProviderIsNew}
              />
              <FormErrorMessage>
                {String(errors?.city?.message)}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.state)}>
              <FormLabel fontSize="sm">State</FormLabel>
              <Select
                {...register('state', { required: true })}
                isDisabled={!selectedProviderIsNew}
              >
                {usStates.map(({ label, value }) => (
                  <option key={`state-${value}`} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {String(errors?.state?.message)}
              </FormErrorMessage>
            </FormControl>
          </Stack>
        </>
      )}

      <FormControl isInvalid={Boolean(errors.ratingOverall)}>
        <FormLabel fontSize="sm">{REVIEW_RATING_LABELS[formType]}</FormLabel>
        <ReviewStarsInput
          onChange={(value: number) => {
            handleChangeStars('ratingOverall', String(value));
          }}
        />
        <FormErrorMessage>
          {String(errors?.ratingOverall?.message)}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={Boolean(errors.reviewBody)}>
        <FormLabel fontSize="sm">{REVIEW_FIELD_LABELS[formType]}</FormLabel>
        <Textarea
          {...register('reviewBody', { required: true })}
          placeholder={REVIEW_FIELD_PLACEHOLDERS[formType]}
          height="191px"
          resize="none"
        />
        <FormErrorMessage>
          {String(errors?.reviewBody?.message)}
        </FormErrorMessage>
      </FormControl>
      <Show below="lg">
        <ReviewGuidelines
          reviewGuidelinesURL={reviewGuidelinesURL}
          contactUsURL={contactUsURL}
        />
      </Show>
      <Stack spacing="8" direction={{ base: 'column', lg: 'row' }} width="full">
        <FormControl isInvalid={Boolean(errors.authorName)}>
          <FormLabel fontSize="sm">Screen Name</FormLabel>
          <Input {...register('authorName', { required: true })} />
          <FormErrorMessage>
            {String(errors?.authorName?.message)}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors.authorEmail)}>
          <FormLabel fontSize="sm">Email Address</FormLabel>
          <Input
            {...register('authorEmail', { required: true })}
            type="email"
          />
          <FormErrorMessage>
            {String(errors?.authorEmail?.message)}
          </FormErrorMessage>
        </FormControl>
      </Stack>
      <Stack spacing="8" direction={{ base: 'column', lg: 'row' }} width="full">
        <FormControl isInvalid={Boolean(errors.title)}>
          <FormLabel fontSize="sm">Relationship to provider</FormLabel>
          <Select {...register('title', { required: true })}>
            <option value={''}>Select</option>
            {RELATIONSHIPS_TO_PROVIDER[formType]?.map(({ name }) => (
              <option key={`title-${name}`} value={name}>
                {name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{String(errors?.title?.message)}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors.careType)}>
          <FormLabel fontSize="sm">
            Care type or service being reviewed
          </FormLabel>
          <Select {...register('careType', { required: true })}>
            {
              <>
                <option value={''}>Select</option>
                {services?.map(({ id, name }) => (
                  <option key={`careType-${name}`} value={id}>
                    {name}
                  </option>
                ))}
                {selectedProviderIsNew &&
                  Object.entries(DEFAULT_CARE_TYPES).map(([id, name]) => (
                    <option key={`careType-${id}`} value={id}>
                      {name}
                    </option>
                  ))}
              </>
            }
          </Select>
          <FormErrorMessage>
            {String(errors?.careType?.message)}
          </FormErrorMessage>
        </FormControl>
      </Stack>
      {displaySpecialFields && (
        <>
          <Stack
            spacing="8"
            direction={{ base: 'column', lg: 'row' }}
            width="full"
          >
            <FormControl isInvalid={Boolean(errors.ratingFood)}>
              <FormLabel fontSize="sm">Food (optional)</FormLabel>
              <ReviewStarsInput
                onChange={(value: number) => {
                  handleChangeStars('ratingFood', String(value));
                }}
              />
              <FormErrorMessage>
                {String(errors?.ratingFood?.message)}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.ratingActivities)}>
              <FormLabel fontSize="sm">Activities (optional)</FormLabel>
              <ReviewStarsInput
                onChange={(value: number) => {
                  handleChangeStars('ratingActivities', String(value));
                }}
              />
              <FormErrorMessage>
                {String(errors?.ratingActivities?.message)}
              </FormErrorMessage>
            </FormControl>
          </Stack>
          <Stack
            spacing="8"
            direction={{ base: 'column', lg: 'row' }}
            width="full"
          >
            <FormControl isInvalid={Boolean(errors.ratingStaff)}>
              <FormLabel fontSize="sm">Staff (optional)</FormLabel>
              <ReviewStarsInput
                onChange={(value: number) => {
                  handleChangeStars('ratingStaff', String(value));
                }}
              />
              <FormErrorMessage>
                {String(errors?.ratingStaff?.message)}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.ratingFacilities)}>
              <FormLabel fontSize="sm">Facility (optional)</FormLabel>
              <ReviewStarsInput
                onChange={(value: number) => {
                  handleChangeStars('ratingFacilities', String(value));
                }}
              />
              <FormErrorMessage>
                {String(errors?.ratingFacilities?.message)}
              </FormErrorMessage>
            </FormControl>
          </Stack>
          <Stack
            spacing="8"
            direction={{ base: 'column', lg: 'row' }}
            width="full"
          >
            <FormControl isInvalid={Boolean(errors.ratingValue)}>
              <FormLabel fontSize="sm">Value (optional)</FormLabel>
              <ReviewStarsInput
                onChange={(value: number) => {
                  handleChangeStars('ratingValue', String(value));
                }}
              />
              <FormErrorMessage>
                {String(errors?.ratingValue?.message)}
              </FormErrorMessage>
            </FormControl>
          </Stack>
        </>
      )}
      <Show below="lg">
        <HStack width="full" pb={8}>
          <ContactUs contactUsURL={contactUsURL} />
        </HStack>
      </Show>
    </VStack>
  );
};

export default ReviewForm;
