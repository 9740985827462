import { Box, Icon, Stack } from '@chakra-ui/react';
import { MdStar, MdStarOutline } from 'react-icons/md';
import { useStep } from '~/hooks/use-step';

interface Props {
  size?: number;
  maxGrade?: number;
  onChange: (value: number) => void;
}

const ReviewStarsInput: React.FC<Props> = ({
  size = 9,
  maxGrade = 5,
  onChange,
}) => {
  const steps = [...Array(maxGrade)].map((_, index) => ({ id: index + 1 }));

  const [currentStep, { setStep }] = useStep({ maxStep: maxGrade });

  const handleChange = (id: number) => {
    setStep(id);
    onChange(id);
  };

  return (
    <Stack direction="row" spacing="1">
      {steps.map(({ id }) => {
        const isActive = currentStep === id;
        const isCompleted = currentStep > id;
        return (
          <Box
            key={id}
            cursor="pointer"
            onClick={() => handleChange(id)}
            aria-label={`Star value ${id}`}
            data-testid={`star-${id}`}
          >
            {isActive || isCompleted ? (
              <Icon as={MdStar} h={size} w={size} color="green" />
            ) : (
              <Icon as={MdStarOutline} h={size} w={size} color="green" />
            )}
          </Box>
        );
      })}
    </Stack>
  );
};

export default ReviewStarsInput;
