import { getCareTypeLabel } from '@components/Search/utils';
import { AlgoliaService } from '@services/algolia/service';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@utils/queryKey';
import { REVIEW_TYPES } from '~/constants';
import { Domain } from '~/types/Domains';
import { ModularMonolithClient } from '@services/modular-monolith/client';
import { GetNearbyProvidersFilters } from '@services/modular-monolith/types/search.type';
import { Provider } from '~/contexts/Provider';

/**
 * Handles loading providers near a coordinate and radius
 */

export interface ProvidersNearMeQueryResponse {
  results: Provider[];
  pages: number;
  resultCount?: number;
}

export const fetchNearbyProviders = async ({
  careType,
  hitsPerPage,
  page,
  latitude,
  longitude,
  enhancedListing = undefined,
  domain = '',
}: {
  careType: string;
  hitsPerPage: number;
  page: number;
  latitude: number;
  longitude: number;
  enhancedListing?: boolean;
  domain?: string;
}) => {
  const filters: GetNearbyProvidersFilters = {
    ...(careType ? { careType } : {}),
    ...(enhancedListing !== undefined ? { enhancedListing } : {}),
    hitsPerPage,
    page,
    radiusForSearch: 25,
    latitude: latitude.toString(),
    longitude: longitude.toString(),
  };

  const modMonClient = new ModularMonolithClient(domain as Domain);
  const data = await modMonClient.getNearbyProviders(filters);

  return {
    results: data.results,
    pages: data.totalPages,
    queryId: data.queryId,
    listId: data.listId,
  };
};

interface FetchProvidersProps {
  careType?: string;
  hitsPerPage: number;
  page: number;
  keywords?: string;
  formType: REVIEW_TYPES;
}

export const useProvidersSearchQuery = ({
  careType,
  hitsPerPage,
  page,
  keywords,
  formType,
}: FetchProvidersProps) => {
  const resolver = async () => {
    if (formType !== REVIEW_TYPES.GENERIC || !keywords)
      return { results: [], pages: 0, resultCount: 0 };

    const algoliaSettings: any = {
      ...(careType && { tagFilters: [getCareTypeLabel(careType)] }),
      hitsPerPage,
      page,
    };

    const algoliaService = new AlgoliaService();

    const data = await algoliaService.getProviders(
      algoliaSettings,
      keywords ?? ''
    );

    return {
      results: data?.hits,
      pages: data?.nbPages,
    };
  };

  return useQuery<ProvidersNearMeQueryResponse>(
    [QueryKey.ProvidersSearch, keywords],
    resolver
  );
};
