import { REVIEW_TYPES } from '~/constants';
import { getUTMParameters } from '@utils/utmParameters';

export const formFieldTypes = {
  reviewBody: 'text',
  authorName: 'text',
  authorEmail: 'text',
  title: 'select',
  careType: 'select',
  locationId: 'text',
  city: 'text',
  state: 'text',
  ratingOverall: 'text',
  ratingFood: 'text',
  ratingActivities: 'text',
  ratingStaff: 'text',
  ratingFacilities: 'text',
  ratingValue: 'text',
  provider: 'text',
};

export const formFieldPrompts = {
  reviewBody: 'What should care seekers or potential residents know?',
  authorName: 'Screen Name',
  authorEmail: 'Email Address',
  title: 'Relationship to provider',
  careType: 'Care type or service being reviewed',
  locationId: 'Provider',
  city: 'City',
  state: 'State',
  ratingOverall: 'How would you rate this agency?',
  ratingFood: 'Food (optional)',
  ratingActivities: 'Activities (optional)',
  ratingStaff: 'Staff (optional)',
  ratingFacilities: 'Facility (optional)',
  ratingValue: 'Value (optional)',
  provider: 'Provider',
};

const PORTAL_EMAIL_SOURCE = 'portal_email';
export const getSourceName = (domain: string, formType: REVIEW_TYPES) => {
  const utmSource = getUTMParameters().utm_source;
  if (utmSource === PORTAL_EMAIL_SOURCE) {
    return PORTAL_EMAIL_SOURCE;
  }
  switch (domain) {
    case 'caring.com':
      return formType === REVIEW_TYPES.GENERIC ? 'new' : 'provider_page';
    case 'seniorhomes.com':
      return 'seniorhomes.com';
    default:
      return 'provider_page';
  }
};
