import {
  Box,
  Link,
  ListItem,
  Show,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import ContactUs from './ContactUs';

interface Props {
  reviewGuidelinesURL?: string;
  contactUsURL?: string;
}

const ReviewGuidelines: React.FC<Props> = ({
  reviewGuidelinesURL,
  contactUsURL,
}) => {
  return (
    <VStack spacing={4} align="left" shouldWrapChildren>
      <Show above="lg">
        <Text as="h1" fontWeight="bold" fontSize="xl">
          Review Guidelines
        </Text>
        <Text as="h3" fontWeight="bold" fontSize="sm" mt={8} color="green.600">
          Ensure your review is approved by following the guidelines, such as
        </Text>
      </Show>
      <Show below="lg">
        <Text as="h3" fontWeight="bold" fontSize="sm">
          Ensure your review is approved by following these guidelines
        </Text>
      </Show>
      <Box>
        <UnorderedList spacing="1.5rem" ml={5} fontSize="sm">
          <ListItem>
            Submit an original review you haven’t published on other websites
          </ListItem>
          <ListItem>
            Have firsthand experience with the listed service provider within
            three (3) years of review submission
          </ListItem>
          <ListItem>Avoid libel, hate speech, and obscene content</ListItem>
          <ListItem>Do not reveal another person’s full name</ListItem>
          <ListItem>
            Do not have a known or potential financial conflict of interest
          </ListItem>
        </UnorderedList>
      </Box>
      <Link
        href={
          reviewGuidelinesURL ||
          'https://www.caring.com/about/review_guidelines/'
        }
        target="_blank"
        fontWeight="bold"
        color="green.600"
        fontSize="sm"
      >
        View All Guidelines
      </Link>
      <Show above="lg">
        <ContactUs contactUsURL={contactUsURL} />
      </Show>
    </VStack>
  );
};

export default ReviewGuidelines;
