import {
  Button,
  Container,
  Heading,
  HStack,
  Show,
  Stack,
  useToast,
} from '@chakra-ui/react';
import ReviewForm from '@components/Account/Reviews/ReviewForm';
import ReviewGuidelines from '@components/Account/Reviews/ReviewGuidelines';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateSeniorLivingReviewMutation } from '@hooks/reviews';
import trackAnalyticsEvent from '@utils/analytics';
import React, { useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { z as zod } from 'zod';
import { REVIEW_TYPES } from '~/constants';
import SiteContext from '~/contexts/SiteContext';
import {
  stepContent,
  stepSubmission,
} from '@components/Analytics/events/eventContracts';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { Metadata } from '~/types/Magnolia';
import useFormStepSubmission, {
  FormType,
} from '@components/Analytics/events/FormStepSubmission';
import useFormSubmission from '@components/Analytics/events/FormSubmission';
import {
  formFieldPrompts,
  formFieldTypes,
} from '@components/Account/Reviews/ReviewForm.utils';

export interface ReviewFormProps {
  reviewGuidelinesURL?: string;
  metadata: Pick<Metadata, '@id'>;
  successMessage?: string;
}

const required_rate_message = 'Rate is required';

// Review Submission New Form Schema
const ReviewSubmissionsNewSchema = zod.object({
  reviewBody: zod.string().trim().min(1, {
    message:
      'Review text is required to submit a review. Please add 1-3 sentences of feedback.',
  }),
  authorName: zod.string().trim().min(5, {
    message: 'Please enter at least 5 characters',
  }),
  authorEmail: zod.string().email(),
  title: zod.string().min(1, {
    message: 'Relationship to provider is required',
  }),
  careType: zod.string().trim().min(1, {
    message: 'Care type or service is required',
  }),
  locationId: zod.string().trim().nullable().optional(),
  facilityName: zod.string().trim().min(1, {
    message: 'Provider is required',
  }),
  city: zod.string().trim().min(1, {
    message: 'City is required',
  }),
  state: zod.string().trim().min(1, {
    message: 'State is required',
  }),
  ratingOverall: zod
    .string({
      required_error: required_rate_message,
    })
    .trim()
    .min(1, {
      message: required_rate_message,
    }),
  ratingFood: zod.string().nullable().optional(),
  ratingActivities: zod.string().nullable().optional(),
  ratingStaff: zod.string().nullable().optional(),
  ratingFacilities: zod.string().nullable().optional(),
  ratingValue: zod.string().nullable().optional(),
  provider: zod.string().nullable().optional(),
  legacyResourceId: zod.string().nullable().optional(),
});

export type ReviewSubmissionsNewSchemaType = zod.infer<
  typeof ReviewSubmissionsNewSchema
>;

export const SuccessToastDescription = ({
  domain,
  message = 'Your review is being processed. If approved it will appear in 3 business days.',
}: {
  domain: string;
  message?: string;
}) => {
  const emailMap = {
    'caring.com': 'reviews@caring.com',
    'seniorhomes.com': 'reviews@seniorhomes.com',
  };

  return (
    <>
      {message}
      <br />
      Have a question? Contact{' '}
      <a
        href={`mailto:${emailMap[domain]}`}
        style={{ color: 'white', textDecoration: 'underline' }}
      >
        {emailMap[domain]}
      </a>
    </>
  );
};

const ReviewSubmissionsNew: React.FC<ReviewFormProps> = ({
  reviewGuidelinesURL,
  metadata,
  successMessage,
}) => {
  const siteContext = useContext(SiteContext);
  const domain = siteContext.site?.path || '';

  const toast = useToast();
  const { isLoading, mutate } = useCreateSeniorLivingReviewMutation();
  const formStepSubmission = useFormStepSubmission();
  const formSubmission = useFormSubmission();

  const formFieldsNamespace = [reviewGuidelinesURL].join(' ');

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
    trigger,
    control,
  } = useForm({
    mode: 'onBlur',
    resolver: zodResolver(ReviewSubmissionsNewSchema),
    defaultValues: {
      reviewBody: '',
      authorName: '',
      authorEmail: '',
      title: '',
      careType: '',
      city: '',
      state: '',
      ratingOverall: '',
      ratingFood: '',
      ratingActivities: '',
      ratingStaff: '',
      ratingFacilities: '',
      ratingValue: '',
      provider: '',
      facilityName: '',
      locationId: '',
      legacyResourceId: '',
    },
  });

  const getSourceName = (domain: string, formType: REVIEW_TYPES) => {
    switch (domain) {
      case 'caring.com':
        return formType === REVIEW_TYPES.GENERIC ? 'new' : 'provider_page';
      case 'seniorhomes.com':
        return 'seniorhomes.com';
      default:
        return 'provider_page';
    }
  };

  const sourceName = getSourceName(domain, REVIEW_TYPES.GENERIC);

  const handleFormEvents = useCallback(
    (data, formInstanceId: string) => {
      const formTemplateId = uuidv5(formFieldsNamespace, metadata['@id']);
      const formStepInstanceId = uuidv4();
      const stepContent: stepContent[] = [];
      const stepSubmission: stepSubmission[] = [];
      const stepIndex = 1;
      const stepId = uuidv5(String(stepIndex), metadata['@id']);
      if (Object.keys(data).length > 0) {
        Object.entries(data).map((prompt, i) => {
          const field = prompt[0];
          const value = prompt[1] as string;

          stepContent.push({
            prompt_id: uuidv5(field, metadata['@id']),
            prompt_type: formFieldTypes[field] ?? '',
            prompt_instance_id: uuidv4(),
            prompt_index: i + 1,
            prompt_value: formFieldPrompts[field] ?? '',
            response_array: [
              {
                response_value: value,
                response_id: uuidv4(),
              },
            ],
          });
        });
        stepSubmission.push({
          step_id: stepId,
          step_instance_id: formStepInstanceId,
          step_index: stepIndex,
        });
      }

      formStepSubmission({
        form_template_id: formTemplateId,
        form_instance_id: formInstanceId,
        form_type: FormType.REVIEW,
        step_id: stepId,
        step_instance_id: formStepInstanceId,
        step_index: 1,
        step_content: stepContent,
      });
      formSubmission({
        form_template_id: formTemplateId,
        form_instance_id: formInstanceId,
        step_submissions: stepSubmission,
        form_type: FormType.REVIEW,
      });
    },
    [metadata, formFieldsNamespace, formStepSubmission, formSubmission]
  );

  const onSubmit = handleSubmit((data) => {
    const formInstanceId = uuidv4();
    mutate(
      {
        legacyResourceId: data.locationId ? data.careType : null,
        locationId: data.locationId ? String(data.locationId) : null,
        facilityName: String(data.facilityName),
        resourceType: data.locationId ? null : data.careType,
        city: String(data.city),
        state: String(data.state),
        title: String(data.title),
        ratingOverall: Number(data.ratingOverall),
        ratingStaff: Number(data.ratingStaff) || null,
        authorName: String(data.authorName),
        authorEmail: String(data.authorEmail),
        ratingActivities: Number(data.ratingActivities) || null,
        ratingFood: Number(data.ratingFood) || null,
        ratingFacilities: Number(data.ratingFacilities) || null,
        ratingValue: Number(data.ratingValue) || null,
        sourceName,
        body: String(data.reviewBody),
      },
      {
        onSuccess: (response) => {
          trackAnalyticsEvent('reviews_generic_review', {
            event_category: 'reviews',
          });

          handleFormEvents(
            { ...data, response: response.message },
            formInstanceId
          );

          toast({
            status: 'success',
            title: 'Review submitted',
            description: (
              <SuccessToastDescription
                domain={domain}
                message={successMessage}
              />
            ),
            duration: null,
            containerStyle: {
              maxWidth: 'unset',
            },
            position: 'top',
          });
          handleCancel();
        },
      }
    );
  });

  const handleCancel = () => {
    reset();
  };

  return (
    <Container maxWidth="container.xl" marginBottom="20px">
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={8} pt={6}>
        <Stack width="full" pl={{ base: 2, lg: 6 }}>
          <Heading size="xl">Share Your Thoughts</Heading>
          <form onSubmit={onSubmit}>
            <ReviewForm
              register={register}
              setValue={setValue}
              errors={errors}
              setError={setError}
              trigger={trigger}
              control={control}
              formType={REVIEW_TYPES.GENERIC}
              reviewGuidelinesURL={reviewGuidelinesURL}
            />
            <HStack
              spacing={5}
              width="full"
              justify={{ base: 'flex-start', lg: 'flex-end' }}
              align="flex-end"
              mt={6}
            >
              <Button
                variant="outline"
                size="md"
                minWidth="112px"
                width="auto"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                bg="primary.500"
                _hover={{ bg: 'primary.600' }}
                color="white"
                size="md"
                minWidth="112px"
                width="auto"
                isLoading={isLoading}
              >
                Submit
              </Button>
            </HStack>
          </form>
        </Stack>
        <Show above="lg">
          <Stack borderLeft="1px" borderColor="gray.300" pl={8} maxW="xs">
            <ReviewGuidelines reviewGuidelinesURL={reviewGuidelinesURL} />
          </Stack>
        </Show>
      </Stack>
    </Container>
  );
};

export default withHydrationOnDemand({
  on: ['idle', 'visible'],
})(ReviewSubmissionsNew);
