import { REVIEW_TYPES } from '~/constants';

export const RELATIONSHIPS_TO_PROVIDER = {
  [REVIEW_TYPES.SENIOR_LIVING]: [
    { name: 'I visited this facility' },
    { name: 'I am/was a resident of this facility' },
    { name: 'I am a friend or relative of a current/past resident' },
    { name: 'I am an employee' },
    { name: 'Other' },
  ],
  [REVIEW_TYPES.SENIOR_CARE]: [
    { name: 'I am a current client of this provider' },
    { name: 'I am a past client of this provider' },
    { name: 'I am an employee' },
    { name: 'Other' },
  ],
  [REVIEW_TYPES.GENERIC]: [
    { name: 'I visited this facility' },
    { name: 'I am/was a resident of this facility' },
    { name: 'I am a friend or relative of a current/past resident' },
    { name: 'I am an employee' },
    { name: 'Other' },
    { name: 'I am a current client of this provider' },
    { name: 'I am a past client of this provider' },
  ],
};

export const REVIEW_FIELD_PLACEHOLDERS = {
  [REVIEW_TYPES.SENIOR_LIVING]:
    'How are the rooms?\nHow was the food?\nHow are your interactions with the staff?',
  [REVIEW_TYPES.GENERIC]:
    'How are the rooms?\nHow was the food?\nHow are your interactions with the staff?',
  [REVIEW_TYPES.SENIOR_CARE]:
    'How are your interactions with the caregiver(s)?\nHow responsive has the agency been to your request?',
};

export const REVIEW_FIELD_LABELS = {
  [REVIEW_TYPES.SENIOR_LIVING]:
    'What should care seekers or potential residents know?',
  [REVIEW_TYPES.GENERIC]:
    'What should care seekers or potential residents know?',
  [REVIEW_TYPES.SENIOR_CARE]: 'What services does the agency provide you?',
};

export const REVIEW_RATING_LABELS = {
  [REVIEW_TYPES.SENIOR_LIVING]: 'How would you rate this community?',
  [REVIEW_TYPES.GENERIC]: 'How would you rate this provider?',
  [REVIEW_TYPES.SENIOR_CARE]: 'How would you rate this agency?',
};

// Map of immutable_name => name of ModMon SERVICE_CATEGORIES at
// `database/seeds/locations/values/service-categories.values.ts`
export const DEFAULT_CARE_TYPES = {
  not_sure: 'Not Sure',
  assisted_living_facilities: 'Assisted Living',
  independent_living: 'Independent Living',
  nursing_homes: 'Nursing Homes',
  homecare_agencies: 'Home Care',
  home_healthcare_agencies: 'Home Health Agencies',
  hospices: 'Hospice',
  continuing_care_retirement_communities: 'Continuing Care Communities',
  adult_day_services: 'Adult Day Care',
  geriatric_care_managers: 'Geriatric Care Managers',
  alzheimers_care_facilities: 'Memory Care',
  low_income_affordable: 'Low Income Affordable',
  senior_apartments: 'Senior Apartment',
};
