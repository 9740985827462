import { Box, Link, Text } from '@chakra-ui/react';

interface Props {
  contactUsURL?: string;
}

const ContactUs: React.FC<Props> = ({ contactUsURL }) => {
  return (
    <Box mt={4}>
      <Text fontSize="sm">Have Questions?</Text>
      <Link
        href={contactUsURL || 'https://www.caring.com/about/contact/'}
        target="_blank"
        fontWeight="bold"
        color="green.600"
        fontSize="sm"
      >
        Contact Us
      </Link>
    </Box>
  );
};

export default ContactUs;
